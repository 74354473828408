<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <p display-2>{{data.message}}</p>
</mat-dialog-content>


<div
  class="button-right"
  mat-dialog-actions
>
  <button
    (click)="onDismiss()"
    color="primary"
    mat-button
  >Discard</button>
  <button
    (click)="onConfirm()"
    color="primary"
    mat-button
  >Save</button>
</div>